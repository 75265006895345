<template>
  <div>
    <div class="booking-list px-7">
      <div class="list-header pt-3 pb-2">
        <span>
          {{ $vuetify.breakpoint.smAndUp ? "Total " : "" }}Bookings:
          {{ totalBookings }}
        </span>
        <span>
          {{ $vuetify.breakpoint.smAndUp ? "Total " : "" }}Guests:
          {{ totalGuests }}
        </span>
        <v-text-field
          class="booking-search"
          v-model="search_term"
          append-icon="mdi-magnify"
          label="Search guest name"
          height="21px"
          single-line
          hide-details
        ></v-text-field>
        <v-icon class="print-icon" @click="print_view">mdi-printer</v-icon>
      </div>
      <transition name="fade" mode="out-in">
        <v-progress-linear
          class="booking-list-loader"
          color="primary"
          height="5px"
          absolute
          bottom
          rounded
          indeterminate
          v-if="is_fetching"
        />
        <div class="booking-column-wrapper grey-scroll-bar" v-else>
          <span
            class="booking-span"
            v-for="booking in bookings_chronologically.filter(
              (b) => !b.parent_booking_id && !b.booking_block_out_id
            )"
            :key="`booking_${booking.id}`"
            @click="select_booking(booking)"
          >
            {{ get_booking_list_text(booking) }}
          </span>
          <span
            class="booking-span text-h6"
            v-if="!bookings_chronologically.length"
          >
            {{
              search_term
                ? "Sorry, we cannot find any matches"
                : "There are currently no bookings."
            }}
          </span>
        </div>
      </transition>
    </div>
    <v-simple-table fixed-header class="booking-table">
      <thead>
        <tr>
          <th class="text-center">Time</th>
          <th class="text-center">Guest Name</th>
          <th class="text-center">Guest Count</th>
          <th class="text-center">Table</th>
          <th class="text-center">Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="booking in bookings_chronologically"
          :key="`booking_table_${booking.id}`"
        >
          <th class="text-center">
            {{ moment(booking.start_at).utc().format("HH:mm") }}
          </th>
          <th class="text-center">{{ booking.user.name }}</th>
          <th class="text-center">{{ booking.guest_count }}</th>
          <th class="text-center">{{ booking.table.name }}</th>
          <th class="text-center">{{ booking.notes }}</th>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "BookingList",
  data() {
    return {
      moment,
      search_term: "",
      autocomplete_timeout_id: null,
    };
  },
  props: {
    totalBookings: {
      type: Number,
      default: 0,
    },
    totalGuests: {
      type: String,
      default: "0",
    },
  },
  computed: {
    ...mapState("UserStore", ["api_user_id"]),
    ...mapState("TableBookingStore", [
      "filters",
      "is_fetching",
      "selected_booking_date",
    ]),
    ...mapGetters("TableBookingStore", ["bookings_chronologically"]),
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "add_filter",
      "remove_filter",
      "get_bookings",
      "select_booking",
    ]),
    get_booking_list_text(booking) {
      const start_time = moment(booking.start_at).utc().format("HH:mm");
      let name;
      const is_walk_in =
        booking.staff_id || booking.user_id === this.api_user_id;
      if (is_walk_in) {
        name = "Walk-in";
      }
      if (!name) name = booking.user.name.split(" ")[0];
      const guests = booking.extra_guest_count
        ? `${booking.guest_count} + ${booking.extra_guest_count}`
        : booking.guest_count;
      const group_unit = booking.guest_count > 1 ? "ppl" : "p";
      return `${start_time}: ${name}, ${booking.table.name}, ${guests} ${group_unit}`;
    },
    print_view() {
      const doc_title = document.title;
      const new_title = `We Are Locals | Bookings for ${moment(
        this.selected_booking_date
      ).format("YYYY/MM/DD")}`;

      window.onafterprint = () => {
        document.title = doc_title;
      };

      document.title = new_title;
      window.print();
    },
  },
  watch: {
    search_term(search_term) {
      if (search_term.length > 1) {
        this.add_filter({ autocomplete: search_term.trim() });
      } else {
        this.remove_filter("autocomplete");
      }
      if (this.autocomplete_timeout_id)
        clearTimeout(this.autocomplete_timeout_id);

      this.autocomplete_timeout_id = setTimeout(() => {
        this.get_bookings();
      }, 500);
    },
    selected_booking_date() {
      this.search_term = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.booking-table {
  visibility: hidden;
  position: absolute;
  max-height: 0;
  overflow-y: hidden;
}

.booking-list ::v-deep {
  height: 187px;
  background: white;
  border-radius: 8px;
  position: relative;
  .list-header {
    height: 42px;
    position: relative;
    span {
      display: inline-block;
      height: 100%;
      font-size: 12px;
      color: #666666;
      font-weight: 600;
      @media (min-width: 600px) {
        margin-right: 2rem;
      }
      @media (min-width: 1155px) {
        font-size: 15px;
      }
    }
    .print-icon {
      position: absolute;
      top: 15px;
      right: -10px;
    }
  }
  .booking-column-wrapper {
    max-height: 130px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: hidden;
    overflow-x: scroll;
    margin-top: 15px;
    @media (min-width: 695px) {
      margin-top: 0;
      max-height: 145px;
    }
    &::-webkit-scrollbar {
      height: 5px;
    }
    .booking-span {
      cursor: pointer;
      font-size: 11px;
      line-height: 1.82;
      width: 200px;
      color: #666;
      display: block;
      text-overflow: ellipsis;

      &:hover {
        color: #999;
      }
    }
  }
  .booking-search {
    display: inline-block;
    width: 150px;
    padding: 0;
    margin: 0;
    margin-top: -1%;
    i::before {
      margin-bottom: 13px;
    }
    label {
      font-size: 12px;
      overflow: unset;
    }
    input {
      font-size: 12px;
      color: #666;
    }
  }
}
</style>

<style lang="scss">
@media print {
  .v-application--wrap {
    visibility: hidden !important;
  }
  .v-application--wrap {
    .booking-table {
      max-height: 100% !important;
      overflow-y: visible !important;
      visibility: visible !important;
      top: -50px;
      left: -260px;
      min-width: 800px !important;
      tr {
        height: 30px !important;
        th {
          height: 30px !important;
          width: 125px !important;
          &:last-of-type {
            width: 250px !important;
          }
        }
      }
    }
  }
  .v-overlay ~ .v-application--wrap {
    .booking-table {
      visibility: hidden !important;
    }
  }
}
</style>
